import React, { FC } from 'react';
import { graphql } from 'gatsby';

import { Container, Layout } from 'layout';
import Typography from 'common/Typography';
import ListTree from 'components/ListTree';

import './SitemapPage.scss';

const SitemapPage: FC<SitemapPageTypes.SitemapPageProps> = ({
  data: {
    sitemapPage: { urls, seo, sitemapCategories, pageName },
  },
}) => (
  <Layout {...{ urls, seo }} background="sauvignon">
    <Container gutter>
      <Typography
        as="h1"
        className="sitemap-page__title"
        weight="bold"
        size={{ base: 35, md: 45 }}
        padding={{ top: { base: 'xxl', md: 'xxxl' }, bottom: { base: 'xxl', md: 'xxxl' } }}
      >
        {pageName}
      </Typography>
      <div className="sitemap-page__sitemap">
        {sitemapCategories.map(({ title: categoryTitle, listedSites }) => (
          <div key={categoryTitle}>
            <Typography
              as="h2"
              className="sitemap-page__title"
              weight="bold"
              size={{ base: 18, md: 20 }}
              padding={{
                top: 'xs',
              }}
            >
              {categoryTitle}
            </Typography>
            {listedSites ? <ListTree items={listedSites} className="sitemap-page__tree" /> : null}
          </div>
        ))}
      </div>
    </Container>
  </Layout>
);

export const query = graphql`
  query ($lang: String) {
    sitemapPage(lang: { eq: $lang }) {
      pageName
      homePageShortcut
      seo {
        ...SEOStructureFragment
      }
      urls {
        ...UrlsFragment
      }
      sitemapCategories {
        title
        categories
        listedSites {
          ...LinkFragment
        }
      }
    }
  }
`;

export default SitemapPage;
