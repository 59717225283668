import React, { FC } from 'react';
import classNames from 'classnames';

import Link from 'common/Link';

import { ListTreeProps } from './model';

const ListTree: FC<ListTreeProps> = ({ items, className }) => {
  const listTreeClasses = classNames('list-tree', className);

  return (
    <ul className={listTreeClasses} data-testid="list-tree">
      {items.map(({ url, name }) => (
        <li key={name} className="list-tree__item">
          <Link className="list-tree__link" url={url} size={{ base: 15, md: 20 }}>
            {name}
          </Link>
        </li>
      ))}
    </ul>
  );
};

export default ListTree;
